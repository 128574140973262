var site = site || {};
var generic = generic || {};

/**
  * Method to grab a cookie and use that to control DOM elements as needed.
  * Handles the setting and getting of the user cookie defined in cookie.name and set in backend.
  * To find where the cookie is set on backend, look to Request::TransactionLocaleHandler.
  * Example cookie structure not signed in:
  *   FE_USER_CART=item_count:1&first_name:&signed_in:0&region_id:0
  * Example cookie structure signed in:
  *   FE_USER_CART=item_count:3&first_name:John&signed_in:1&region_id:0
  * You can set specific functions on page load using events.load or hook into javascript events
  *  by defining them in the events class and adding the event to events.init.
  * The cookie class is used to handle all the cookie functionality such as the setting and getting.
  * This method is meant to be stand alone so should be able to add to a brand without many tweaks.
  * Uses straight javascript so not dependent on a javascript framework except for DOM load.
  * Preferably added to the where ever the globalnav javascript is added within a brand.
  * @memberOf site
*/
site.userInfoCookie = function() {
  // Set global vars here.
  var nodes = {};

  // Private internal cookie class.
  // Leverages generic.cookie to get and set the cookie values.
  var cookie = {
    name: 'FE_USER_CART',
    value: '',
    regEx: function(key) {
      if (!key) {
        return null;
      }
      return new RegExp('(?:^|&)' + key + ':([^;&,}]*)');
    },
    set: function() {
      if (!this.name) {
        return null;
      }
      var userCookie = generic.cookie(this.name);
      this.value = userCookie ? userCookie : '';
    },
    getValue: function(key) {
      var keyVal = this.value.match(this.regEx(key));
      return keyVal ? (keyVal[1] ? keyVal[1] : null) : null;
    },
    setValue: function(key, val) {
      var match  = this.value.match(this.regEx(key));
      var oldValue = match ? match[0] : '';
      var newValue = this.value.replace(oldValue, oldValue.split(':')[0] + ':' + val);
      // NOTE: disabling this because it causes problems if on the perlgem side the site is
      // configured with USE_BASE_DOMAIN_COOKIE = 0 or unconfigured.
      // Code is also now disabled on the perlgem side so we don't end up with multiple, mismatched FE_USER_CART cookies
      var props = {
        path : '/'
        //path : '/',
        //domain : Drupal.settings.cl.FE_USER_CART.domain
      };
      generic.cookie(this.name, newValue, props);
      this.value   = newValue;
    }
  };

  // Private events class that handles all individual events.
  // Add all events in 'init' method so they will get fired on page load.
  // The cart event is commented out but there as an example.
  var events = {
    init: function() {
      this.load();
      this.cart();
    },
    load: function() {
      _setCartItemsTotal();
    },
    cart: function() {
      $(document).on('cart.updated addToCart.success', function(event, cartResult) {
        var ccount = (typeof cartResult.getCount != "undefined") ? cartResult.getCount()
                      : (typeof cartResult.trans_data.items_count != "undefined") ? cartResult.trans_data.items_count
                      : 0;
        if (ccount > 0) {
          cookie.setValue('item_count', ccount);
          _setCartItemsTotal();
        }
      });
    }
  };

  /* Additional helper functions below here. */

  // Pulls in the cookie item_count and updates the DOM;
  var _setCartItemsTotal = function() {
    var valueKey = 'item_count';
    var itemsTotal = cookie.getValue(valueKey) || '0';
    // note: cookie uses 'item_count' but nodes are tagged with 'items_count'
    $('[data-pg-prop="items_count"]').each(function(i, node){
      $(node).html((itemsTotal === '0') ? '' : itemsTotal);
      // hide those marked to be hidden if zero count
      $(node).toggleClass('hidden', $(node).hasClass('hide_if_zero') && (itemsTotal === '0'));
    });
  };

  return {
    init: function() {
      cookie.set();
      events.init();
    },
    set: function() {
      cookie.set();
    },
    getValue: function(key) {
      return cookie.getValue(key);
    },
    setValue: function(key, val) {
      cookie.setValue(key, val);
    }
  };
}();

// BRAND SPECIFIC: This is for a prototype site.
// $(document).ready(function(){ site.userInfoCookie.init(); });

// Set the cookie outside of $(document).ready so other scripts can always access it in their $(document).ready:
 site.userInfoCookie.set();
